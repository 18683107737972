.main-content {
  margin-top: 100px;
  margin-left: 150px;
  display: flex;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main-content {
    margin-left: 70px;
    display: flex;
    width: 100%;
    justify-content: center !important;
    align-items: start !important;
  }
}
