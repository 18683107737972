.history-container .project-container {
  width: 700px;
  height: 300px;
  background-color: rgb(243, 243, 243);
  border-radius: 30px;
  margin-top: 50px;
}
.image-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(194, 194, 194);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  margin-right: 10px;
}
