.project-container {
  height: 250px;
}
.project-info {
  height: 100%;
}
.photo-container {
  margin: 0;
  width: 100%;
}
