.item-block {
  border: 1px solid rgb(176, 176, 176);
  display: flex;
  margin-left: 50px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 150px;
}
.item-block :hover {
  border: 1px solid rgb(0, 0, 0);
}
.item-container-container {
  width: 200px;
  height: 150px;
}

.item-link {
  display: flex;
  color: black;
  margin: 20px;
  width: 100%;
  transition-property: box-shadow;
  transition-duration: 0.2s;
}
.item-link :hover {
  color: black;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.item-container:first-child {
  margin-left: 0;
}
.item-container {
  display: flex;
  margin-left: 50px;
}

@media screen and (max-width: 768px) {
  a {
    margin-left: 0px;
  }
  .item-container:first-child {
    margin-left: 0;
  }
}
